@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Montserrat SemiBold';
    src: url(fonts/Montserrat-SemiBold.ttf)
}

@font-face {
    font-family: 'Amiri Regular';
    src: url(fonts/Amiri-Regular.ttf)
}

@font-face {
    font-family: 'Amiri Bold';
    src: url(fonts/Amiri-Bold.ttf)
}

@font-face {
    font-family: 'Amiri Bold Italic';
    src: url(fonts/Amiri-BoldItalic.ttf)
}

@font-face {
    font-family: 'Amiri Italic';
    src: url(fonts/Amiri-Italic.ttf)
}

@layer utilities {
  .ts-1px {
    text-shadow: 1px 1px 2px black;
  }

  .ts-2px {
    text-shadow: 2px 2px 4px black;
  }
}

/**
 * Custom classes for markdown.
 */
 .custom-md {
  @apply font-ar !important bg-transparent;
 }

.custom-md h1 {
  @apply font-msb text-3xl border-none !important;
}

.custom-md h2 {
  @apply font-msb text-2xl  border-none !important;
}

.custom-md h3 {
  @apply font-msb text-xl  border-none !important;
}

.custom-md p {
  @apply font-ar text-lg  border-none !important;
}

.custom-md p strong {
  @apply font-ab text-lg !important;
  font-weight: inherit;
}

.custom-md ul {
  @apply list-disc !important;
}